import React from 'react';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';

const FeaturesContainer = styled.section`
  padding: 2rem 0;
  text-align: center;
`;

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 25%;
`;

const FeatureIcon = styled.i`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 0.9rem;
`;

const Features = () => {
  const features = [
    {
      icon: 'fas fa-lightbulb', // Replace with your icon class (e.g., from Font Awesome)
      title: 'Easy to Use',
      description: 'Get started quickly and intuitively with our user-friendly interface.',
    },
    {
      icon: 'fas fa-chart-line', // Replace with your icon class
      title: 'Powerful Features',
      description: 'Unlock a range of functionalities to streamline your workflow.',
    },
    {
      icon: 'fas fa-shield-alt', // Replace with your icon class
      title: 'Secure and Reliable',
      description: 'Enjoy peace of mind with robust security measures and dependable performance.',
    },
    {
      icon: 'fas fa-users', // Replace with your icon class
      title: 'Collaborative',
      description: 'Work seamlessly with your team using our built-in collaboration tools.',
    },
  ];

  return (
    <FeaturesContainer id="features">
      <h2>Our Powerful Features</h2>
      <div className="features-grid">
        {features.map((feature) => (
          <FeatureCard key={feature.title}>
            <FeatureIcon className={feature.icon} />
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureCard>
        ))}
      </div>
      <Link to="#about" className='link'>About</Link>
    </FeaturesContainer>
  );
};

export default Features;
