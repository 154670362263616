import React from 'react';
//import { Link } from 'react-router-dom'; // For navigation links (optional)
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f5f5f5; /* Light gray background */
`;

const Logo = styled.img`
  height: 50px; /* Adjust logo height */
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavLink = styled.li`
  margin-right: 1rem;
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src="logo.jpg" alt="1ai.ma" />
      </Link>
      <NavLinks>
        <NavLink>
          <Link to="#about">About</Link>
        </NavLink>
        <NavLink>
          <Link to="#features" className='page-scroll'>Features</Link>
        </NavLink>
        <NavLink>
          <Link to="#contact">Contact</Link>
        </NavLink>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
