import React from 'react';
import styled from 'styled-components';

const CallToActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  background-color: #f0f0f0; /* Light gray background */
`;

const CallToActionBtn = styled.button`
  background-color: #007bff; /* Blue button color */
  color: #fff; /* White button text */
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #0069d9; /* Darker blue on hover */
  }
`;

const CallToAction = () => {
  return (
    <CallToActionContainer  id="contact">
      <CallToActionBtn>Get Started Today!</CallToActionBtn>
    </CallToActionContainer>
  );
};

export default CallToAction;
